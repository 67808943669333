import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './css/NavBar.css';
import CosmicTonicLogo from '../Static/Cosmic-Tonic-Logo.png';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import {IoMdMenu} from "react-icons/io";

function NavBar(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  //const [Projects, setProjects] = useState('NavBar-buttons');
  const [About, setAbout] = useState('NavBar-buttons');
  const [Contact, setContact] = useState('NavBar-buttons');

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  function handleClose() {
    setAnchorEl(null);
    setOpen(false);
  };

  function handleHighlite(link){
    if(link === "/About"){
      //setProjects('NavBar-buttons');
      setAbout('NavBar-buttonsHighlite');
      setContact('NavBar-buttons');
    }else if(link === "/Projects"){
    //  setProjects('NavBar-buttonsHighlite');
      setAbout('NavBar-buttons');
      setContact('NavBar-buttons');
    }else if(link === "/ContactUs"){
    //  setProjects('NavBar-buttons');
      setAbout('NavBar-buttons');
      setContact('NavBar-buttonsHighlite');
    }else{
    //  setProjects('NavBar-buttons');
      setAbout('NavBar-buttons');
      setContact('NavBar-buttons');
    }
  }
    if(props.windowWidth > 760){
    return(
       <div>
        <AppBar position='static' elevation={1} className="App-AppBar">
            <Toolbar>
            <Grid item xs={6}>
              <Button>
                <Link to="/">
                  <img className="logo-Class" src={CosmicTonicLogo} alt="logo" onClick={() => handleHighlite('Home')}/>
                </Link>
              </Button>
              </Grid>
              <Grid container direction="row" justifyContent="flex-end">
                <div className={About}>
                  <Link to="About" style={{textDecoration: 'none'}}>
                    <Typography variant="h6" style={{color: 'white', fontWeight: 1000}} onClick={() => handleHighlite('/About')}>
                    About
                    </Typography>
                  </Link>
                </div>
                  {/*<div className={Projects}>
                  <Link to="Projects" style={{textDecoration: 'none'}}>
                    <Typography variant="h6" style={{color: 'white', fontWeight: 1000}} onClick={() => handleHighlite('/Projects')}>
                    Projects
                    </Typography>
                  </Link>
                  </div>*/}
                  <div className={Contact}>
                  <Link to="ContactUs" style={{textDecoration: 'none'}}>
                    <Typography variant="h6" style={{color: 'white', fontWeight: 1000}} onClick={() => handleHighlite('/ContactUs')}>
                    Contact Us
                    </Typography>
                  </Link>
                  </div>
              </Grid>
            </Toolbar>
        </AppBar>
        </div>
      );
    }
    else {
      return(
         <div>
          <AppBar position='static' elevation={1} className="App-AppBar">
            <Toolbar>
            <Grid item xs={6}>
              <Button>
                <Link to="/">
                  <img className="logo-Class" src={CosmicTonicLogo} alt="logo"/>
                </Link>
              </Button>
              </Grid>
              <Grid container direction="row" justifyContent="flex-end">
                <Button>
                  <IoMdMenu aria-controls="fade-menu" aria-haspopup="true" onClick={(e) => handleClick(e)} style={{color: "white"}}/>
                </Button>
                <Menu
                  id="fade-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  <MenuItem onClick={handleClose}>
                      <Link to="About" style={{textDecoration: 'none'}}>
                        <Typography variant="h6" style={{color: 'white'}}>
                        About
                        </Typography>
                      </Link>
                  </MenuItem>
                  {/*<MenuItem onClick={handleClose}>
                      <Link to="Projects" style={{textDecoration: 'none'}}>
                        <Typography variant="h6"style={{color: 'white'}}>
                        Projects
                        </Typography>
                      </Link>
                  </MenuItem>*/}
                  <MenuItem onClick={handleClose}>
                      <Link to="ContactUs" style={{textDecoration: 'none'}}>
                        <Typography variant="h6"style={{color: 'white'}}>
                        Contact Us
                        </Typography>
                      </Link>
                  </MenuItem>
                </Menu>

              </Grid>
            </Toolbar>
          </AppBar>
          </div>
      );
    }
}


export default NavBar;
