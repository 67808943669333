import React from 'react';
import "./css/Dialog.css"
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';


function GameDialog(props){
    if(props.windowWidth > 400){
      return(
        <div>
          <Dialog onClose={props.handleClose} open={props.open}>
            <div className="dialog-size">
              <DialogTitle id="simple-dialog-title">{props.title}</DialogTitle>
              <center><img src={props.Image} className="games-image" alt="Games"/></center>
              <div>
                {props.Paragraph}
              </div>
            </div>
          </Dialog>
        </div>
      )
    }
    else{
      return(
      <div>
        <Dialog onClose={props.handleClose} open={props.open}>
          <div className="dialog-size">
            <DialogTitle id="simple-dialog-title">{props.title}</DialogTitle>
            <center><img src={props.Image} className="dialog-imageMobile" alt="Games"/></center>
            <div>
              {props.Paragraph}
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

export default GameDialog;
