import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import PostDialog from "../Post/PostDialog"
import moment from 'moment';
import dompurify from 'dompurify';
import './css/PreviewPost.css'

function PreviewPost(props)  {
    const [headerImage, setHeaderImage] = useState();
    const [postDate, setPostDate] = useState();
    const [postBody, setPostBody] = useState();
    const [shadow, setShadow] = useState(6);
    const [openDialog, setOpenDialog] = useState(false);
    const sanitizer = dompurify.sanitize;

    function onMouseOver() {
      setShadow(16);
    }
    function onMouseOut(){
      setShadow(6);
    }
    function onClick(){
      setOpenDialog(true);
    }
    function onClose(){
      setOpenDialog(false);
    }


  //Handling blog response
    useEffect(() => {
      var el = document.createElement('html');
      el.innerHTML = sanitizer(props.post.content);
      var imgElements = el.getElementsByTagName('a');
      if(imgElements.length > 0){
        for(var i = 0; i < imgElements.length; i++){
          if(imgElements[i].getElementsByTagName('img').length > 0 && imgElements[i].getElementsByTagName('img')[0].alt === "header-id"){
            setHeaderImage(sanitizer(imgElements[i].getElementsByTagName('img')[0]));
            imgElements[i].remove();
            break;
          }
        }
        setPostBody(sanitizer(el.innerHTML))
      } else {
        setPostBody(sanitizer(props.post.content));
      }
      setPostDate(moment(props.post.published).format('MMMM Do YYYY'));

    }, [props.post.published, props.post.content, sanitizer]);

  return (
    <div className="Post-Container">
    {openDialog ? <PostDialog handleClose={onClose} open={openDialog} postDate={postDate} title={props.post.title} Image={headerImage} content={postBody} /> : null}
      <Paper className="PreviewPost-Paper" onClick={onClick} onMouseOver={onMouseOver} onMouseOut={onMouseOut} elevation={shadow}>
        <div className="Post-Header-Image" dangerouslySetInnerHTML={{__html: headerImage}} />
          <div className="Post-Title">
          {props.post.title}
          </div>
        <div className="Post-Content" dangerouslySetInnerHTML={{__html: postBody}} />
        <div className="Post-Date">{moment(props.post.published).format('MMMM Do YYYY')}</div>
      </Paper>
    </div>
  );
}

export default PreviewPost;
