import React from 'react';
import Paper from '@mui/material/Paper';
import "./css/About.css"
import Typography from '@mui/material/Typography';

function About(){
    return (
      <div>
      <Paper elevation={8} className="App-Paper">
        <div className="Header">
          <br/>
          <Typography variant="h3">About</Typography>
        </div>

        <div className="Body">
        <p>We here at <i>Cosmic Tonic</i> are extremely passionate about crafting positive experiences that our players will remember for a lifetime:
        Whether it be through the bonds forged and laughs shared while playing our games, or by inspiring the next generation of developers to
        turn their dreams into reality.</p>
        <p>Our mission is to bring our imaginations to life, transporting our players to wacky, and interesting places. Places that create entertaining experiences organically formed through the player's actions.</p>
        <p><i>Cosmic Tonic</i> was founded by two dudes with attitude reaching for the stars and striving to make the world a better place! Well,
        the world of video games at least! Inspired by the games of their youth and motivated by a passion burning in their hearts,  <i>Cosmic Tonic</i>'s
        founders sought out to create genuinely enjoyable video games. Video games which, hopefully, will create some truly memorable "game nights" for our players.</p>
        </div>
        </Paper>
      </div>
    );

}

export default About;
