import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import SuperBoyImg from '../Static/PSB_Screenshot.png';
import SteamLogo from '../Static/steamLogo.svg';
import PreviewPost from '../Blog/PreviewPost/PreviewPost'
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import axios from '../api/axios'
import './css/Main.css';

function Main()  {
  const [blogList, setBlogList] = useState([]);
  const [loaded, setLoaded] = useState(false);

//On load call
  useEffect(() => {
    async function fetchBlog(){
      const request = await axios.get('?key=AIzaSyAAM8G7_qThFyhznRCBeKmyDV5Xwl_0N5E');
      setBlogList(request.data.items)
      setLoaded(true)
      return request;
    }
    fetchBlog();
  }, []);

//Handling blog response
  useEffect(() => {
    if(blogList === undefined || blogList.length <= 0)
      return;
  }, [blogList]);

if(blogList === undefined || blogList.length <= 0){
    return(
      <div className="loading-flex">
        <div className="loading-container">
          <CircularProgress className="loading-circle" color="secondary" />
        </div>
      </div>
    );
} else{
    return (
      <Fade in={loaded}>
        <div>
        {console.log(blogList)}
          <div>
            <div className="Button-Container">
              <Button href="https://store.steampowered.com/app/1933520/Hero_Mania/" target="_blank" rel="noopener noreferrer" className="steam-button" variant="contained">
                  <div className="Steam-Container">
                    <img src={SteamLogo} className="steam-logo" alt="Steam"/>
                      <span className="wishlist-text">Wishlist On Steam</span>
                  </div>
              </Button>
            </div>
            <div className="image-body">
              <img src={SuperBoyImg} className="PSB-Image" alt="Games"/>
            </div>
          </div>
          <Paper className="PSB-Paper">
            <div className="paper-content">
              <p><i>Hero Mania</i> is a bombastic, comic-book themed multiplayer party game</p>
              <p>Players step into the sparkling spandex of unfamed, super-powered heroes in an all-out brawl to win fame and glory!</p>
              <p>-- oh, and to save the city of course!</p>
              <p>Choose from a roster of bizarre, silly, and unacclaimed heroes each with their own oddball personalities and powers. Use the environment -- and each other -- to get the upper hand in a fight as you blast, dash, and smash your way to the top to become the city's number one hero!</p>
            </div>
          {/*<Button className="PSB-MoreInfo" variant="contained">More Info</Button>*/}
          </Paper>
          <div>
            <div className="Latest-News">
              <Typography className="Latest-News-Font" varient='h1'>Latest News</Typography>
            </div>
            <div className="news-container">
            {blogList.map(post => {
              return <PreviewPost key={post.id} post={post}/>
            })}
            </div>
          </div>
        </div>
      </Fade>
    );
  }
}

export default Main;
