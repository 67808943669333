import React, { useState, useEffect } from 'react';
import './App.css';
import Main from './homePage/Main';
import About from './AboutPage/About';
import Projects from './ProjectsPage/Projects';
import Contact from './ContactPage/Contact'
import PostPage from './Blog/PostPage/PostPage'
import NotFound from './homePage/NotFound';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import NavBar from './MainComponents/NavBar';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Twitter from './Static/Twitter_Logo_WhiteOnImage.png';
import FaceBook from './Static/f_logo_RGB-White_58.png';
import Instagram from './Static/IG_Glyph_Fill.png';


function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowWidth, setWindowWidth] = useState({
    width: undefined
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
    setWindowWidth(window.innerWidth);
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowWidth;
}

function App() {
  const windowWidth = useWindowSize();
  return (
    <Router>
      <div className='App-background'>
      <NavBar windowWidth={windowWidth} />
      {/*<Paper elevation={8} className="App-Paper">*/}
          <Routes>
            <Route path="/" exact element={<Main />}/>
            <Route path="/About" element={<About />}/>
            <Route path="/Projects" element={<Projects windowWidth={windowWidth} />}/>
            <Route path="/ContactUs" element={<Contact />}/>
            <Route path="/:id" element={<PostPage />}/>
            <Route path="*" element={<NotFound />}/>
          </Routes>
      {/*</Paper>*/}

      <br/>
      <div className="App-Social">
        <Button>
          <Tooltip title="Follow us on Twitter">
            <a href="https://twitter.com/cosmic_tonic" target="_blank" rel="noopener noreferrer"><img className="Social-Logo" src={Twitter} alt="logo"/></a>
          </Tooltip>
        </Button>
        <Button>
          <Tooltip title="Find us on Facebook">
            <a href="https://www.facebook.com/cosmictonicgames/" target="_blank" rel="noopener noreferrer"><img className="Social-Facebook" src={FaceBook} alt="logo"/></a>
          </Tooltip>
        </Button>
        <Button>
          <Tooltip title="Follow us on Instagram">
            <a href="https://www.instagram.com/cosmictonicgames/" target="_blank" rel="noopener noreferrer"><img className="Social-Logo" src={Instagram} alt="logo"/></a>
          </Tooltip>
        </Button>
      </div>
      </div>
    </Router>
  );
}

export default App;
