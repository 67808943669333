import React from 'react';
import "./css/Contact.css"
import Typography from '@mui/material/Typography';
import NetlifyForm from 'react-netlify-form'


function Contact() {
  return (
      <div>
        <div className="Header">
          <br/>
          <Typography variant="h3">Contact Us</Typography>
        </div>
      <div className="Body">
      <NetlifyForm name='Contact'
      recaptcha={{
      sitekey: '6LfjhtsUAAAAAFQe_1EyIEVM7rr6VWARUQIRY0lQ',
      size: 'invisible'
      }}>
          {({ loading, error, success, recaptchaError, recaptcha }) => (
            <div>
              {loading &&
                <div>Loading...</div>
              }
              {error &&
                <div>Your information was not sent. Please try again later.</div>
              }
              {recaptchaError &&
                <div>Recaptcha did not match. Please make sure the box is checked.</div>
              }
              {success &&
                <div>Thank you for contacting us!</div>
              }
              {!loading && !success &&
                <div>
                  <label><Typography variant="h5" style={{paddingTop: '3%'}}>Name:</Typography><input type="text" name="name" className="box" /></label>
                  <label><Typography variant="h5" style={{paddingTop: '3%'}}>Email:</Typography><input type="email" name="email" className="box"/></label>
                  <label><Typography variant="h5" style={{paddingTop: '3%'}}>Subject:</Typography> <input type="text" name="subject" className="box"/></label>
                  <label><Typography variant="h5" style={{paddingTop: '3%'}}>Message:</Typography><textarea name="message" className="box-message"></textarea></label>
                    <center><button type="submit">Send</button></center>
                    <br/>
                </div>
              }
              {recaptcha}
            </div>
          )}
        </NetlifyForm>
    </div>
  </div>
  );
}

export default Contact;
