import React, { useState, useEffect } from 'react';
import { useParams, useNavigate  } from 'react-router-dom'
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import dompurify from 'dompurify';
import axios from '../../api/axios'


function PostPage(props){
  const [post, setPost] = useState();
  const [postDate, setPostDate] = useState();
  const [postBody, setPostBody] = useState();
  const [postTitle, setPostTitle] = useState();
  const [headerImage, setHeaderImage] = useState();
  const navigate = useNavigate();
  const { id } = useParams();
  const sanitizer = dompurify.sanitize;

//On load call
  useEffect(() => {
    async function fetchPost(){
      const request = await axios.get(id + '?key=AIzaSyAAM8G7_qThFyhznRCBeKmyDV5Xwl_0N5E')
      .catch(function (error) {
        navigate("/techDiff")
        return;
      });
      setPost(request.data);
      return request.data;
    }
    fetchPost();
  }, [id, navigate]);

  useEffect(() => {
      var el = document.createElement('html');
      if(post === undefined){
        return;
      }
      el.innerHTML = sanitizer(post.content);
      setPostTitle(post.title);
      var imgElements = el.getElementsByTagName('a');
      if(imgElements.length > 0){
        for(var i = 0; i < imgElements.length; i++){
          if(imgElements[i].getElementsByTagName('img').length > 0 && imgElements[i].getElementsByTagName('img')[0].alt === "header-id"){
            setHeaderImage(sanitizer(imgElements[i].getElementsByTagName('img')[0]));
            imgElements[i].remove();
            break;
          }
        }
        setPostBody(sanitizer(el.innerHTML))
      } else {
        setPostBody(sanitizer(post.content));
      }
      setPostDate(moment(post.published).format('MMMM Do YYYY'));
    }, [post, sanitizer]);

      return(
        <div>
        <Paper elevation={8} className="App-Paper">
          <div className="Header">
            <br/>
            <Typography variant="h3">{postTitle}</Typography>
          </div>
          <div className="dialog-image" dangerouslySetInnerHTML={{__html: headerImage}} />
          <div className="Body">

          <div className="dialog-content" dangerouslySetInnerHTML={{__html: sanitizer(postBody)}} />
          <div className="dialog-date">{postDate}</div>
          </div>
          </Paper>
        </div>
    )
}

export default PostPage;
