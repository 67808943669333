import React from 'react';
import "./css/Dialog.css"
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import dompurify from 'dompurify';


function PostDialog(props){
  const sanitizer = dompurify.sanitize;
      return(
      <div>
        <Dialog maxWidth="xl" fullWidth={true} scroll="body" onClose={props.handleClose} open={props.open}>
          <div className="dialog-size">
            <DialogTitle className="dialog-title" id="simple-dialog-title">{props.title}</DialogTitle>
            <div className="dialog-image" dangerouslySetInnerHTML={{__html: props.Image}} />
            <div className="dialog-content" dangerouslySetInnerHTML={{__html: sanitizer(props.content)}} />
            <div className="dialog-date">{props.postDate}</div>
          </div>
        </Dialog>
      </div>
    )
}

export default PostDialog;
