import React from 'react';
import Typography from '@mui/material/Typography';
import './css/Main.css';

function notfound()  {
  return (

    <div className="main-body">
        {console.log("not found!!")}
    <center>
      <Typography variant="h1" style={{color: 'white'}}>
      404
      </Typography>
      <Typography variant="h2" style={{color: 'white'}}>
      Sorry! The page you are looking for doesn't exist.
      </Typography>
    </center>
    </div>
  );
}

export default notfound;
