import React from 'react';
import './css/Projects.css';
import SuperBoy from "../Games/ProjectSuperBoys/ProjectSuperBoys"
import Typography from '@mui/material/Typography';


function Projects(props)  {
  return (
    <div>
      <div className="Header">
        <br/>
        <Typography variant="h3">Projects</Typography>
      </div>
      <br/>
        <SuperBoy windowWidth={props.windowWidth} />
    </div>
  );
}

export default Projects;
