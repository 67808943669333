import React, { useState } from 'react';
import '../css/Games.css';
import GameDialog from "../../DialogBox/GameDialog"
import SuperBoyImg from '../../Static/ProjectSuperBoys.png';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

function SuperBoy(props) {
  const [shadow, setShadow] = useState(6);
  const [openDialog, setOpenDialog] = useState(false);

  function onMouseOver() {
    setShadow(16);
  }
  function onMouseOut(){
    setShadow(6);
  }
  function onClick(){
    setOpenDialog(true);
  }
  function onClose(){
    setOpenDialog(false);
  }

  const Paragraph = <div className="games-paragraph">
      <p>Project Super Boys is a super hero themed, four player party game</p>
      <p>Take to the streets as a failed super hero in a Golden Age of hero work, overshadowed by glistening spandex and mountains of comic books starring the heroes everyone already knows and loves. </p>
      <p>Push and punch your way to victory in a city oversaturated with super-human potential as you compete against others to make a name for yourself by whatever means necessary! Save the day by sabotaging your friends, battling second-rate super villains, rescuing the neighborhood cat from a tree, or even helping friendly ol' Granny Gretchen cross the street again! Do whatever it takes to rise above the rest, and stop others from stealing your spotlight!</p>
      <p>And remember: To be the best, you don't need to beat the best, you just need to make sure everyone else looks worse!</p>
    </div>

    if(props.windowWidth > 1350){
    return(
      <div className="games-size">
      {openDialog ? <GameDialog handleClose={onClose} open={openDialog} title={'Project Super Boys'} Image={SuperBoyImg} Paragraph={Paragraph} /> : null}
        <Card  className="games-cards" onClick={onClick} onMouseOver={onMouseOver} onMouseOut={onMouseOut} elevation={shadow}>
          <CardContent>
            <Typography align="center" className="header-card-content" variant="h4">Project Super Boys</Typography>
          </CardContent>
          <CardContent>
            <center><img src={SuperBoyImg} className="games-image" alt="Game"/></center>
          </CardContent>
        </Card>
      </div>
    )
    }
    else if(props.windowWidth > 400){
      return(
        <div className="games-size">
        {openDialog ? <GameDialog handleClose={onClose} open={openDialog} title={'Project Super Boys'} Image={SuperBoyImg} Paragraph={Paragraph} /> : null}
          <Card className="games-cards" onClick={onClick} onMouseOver={onMouseOver} onMouseOut={onMouseOut} elevation={shadow}>
            <CardContent>
              <Typography align="center" className="header-card-content" variant="h4">Project Super Boys</Typography>
            </CardContent>
            <CardContent>
              <center><img src={SuperBoyImg} className="games-image-mobile" alt="Game"/></center>
            </CardContent>
          </Card>
        </div>
      )
    }
    else{
      return(
        <div className="games-size">
        {openDialog ? <GameDialog handleClose={onClose} open={openDialog} title={'Project Super Boys'} Image={SuperBoyImg} Paragraph={Paragraph} /> : null}
          <Card className="games-cards" onClick={onClick} onMouseOver={onMouseOver} onMouseOut={onMouseOut} elevation={shadow}>
            <CardContent>
              <Typography align="center" className="header-card-content" variant="h6">Project Super Boys</Typography>
            </CardContent>
            <CardContent>
              <center><img src={SuperBoyImg} className="games-image-mobile-baby" alt="Game"/></center>
            </CardContent>
          </Card>
        </div>
      )
    }
}

export default SuperBoy;
